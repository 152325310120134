import './App.scss';
import cat_gif from './popcat.gif'
import instruction from './instruction.webp'
import ImageGallery from "react-image-gallery";
import p1 from "./p1.jpg";
import p2 from "./p2.webp";
import p3 from "./p3.webp";
import p4 from "./p4.webp";
import p5 from "./p5.webp";
import limited from "./limited.jpg";
import InputMask from "react-input-mask";
import { useEffect, useState } from 'react';
const images = [
  {original: p1},
  {original: p2},
  {original: p4},
  {original: p3},
  {original: p5}
];


function App() {
  const [phone, setPhone] = useState(false);
  const [name, setName] = useState(false);

  useEffect(() => {
    console.log(name, phone);
// eslint-disable-next-line
  }, [phone]);

  function changePhone(e){
    let phone_tmp = e.target.value;
    if(!phone_tmp.includes('_'))
      setPhone(phone_tmp);
    else
      setPhone(false);
  }
  async function sendApply(){
    if(name === false){
      alert('Введіть будь ласка Ваше ім\'я');
      return;
    }
    if(phone === false){
      alert('Заповніть будь ласка номер телефону');
      return;
    }
    fetch('https://cool-cute.art/order.php?phone=' + encodeURIComponent(phone.replaceAll(' ', '')) + '&name=' + name)
      .then(async (data) => {
        if(await data.text() === 'ok')
          alert('Замовлення прийняте, очікуйте дзвінка!');
      });
  }

  return (
    <div className="container">
      {/* <h1 className='text-center'>Брелок з Мемним Котом</h1> */}
      <h1 className='text-center mb-0 fw-bold'>МЕМНИЙ КІТ</h1>
      <h6 className='text-center wheat mb-0'>ГОЛОГРАФІЧНИЙ БРЕЛОК</h6>
      <div className='containered'>
        <img src={cat_gif} className='norm_img mt-1' alt="Гіфка з котом"/>
        <a href="#form"><button type='button' className='btn green_btn btn-lg mt-4 col-12'>ОТРИМАТИ!</button></a>
      </div>
      <div className='containered wheat'>
        <h1 className='text-center fw-bold'>ЧОМУ ВІН?</h1>
        <ul className='mylist mt-3'>
          <li>Універсальний аксесуар</li>
          <li>Гарантоване "Вооу!" від усіх друзів</li>
          <li>Двостороння картинка*</li>
          <li>Якісні ланцюжок та кільце</li>
        </ul>
      </div>
      <h6 className='text-center yellow m-0'>+ ПРИЄМНИЙ БОНУС**</h6>
      <div className='containered'>
        <h2 className='text-center'>ЯК КОРИСТУВАТИСЯ?</h2>
        <img src={instruction} className='norm_img mt-3' alt="Інструкція по коту"/>
        <ol className='mt-4 mylist'>
          <li>Візьміть кота в руку</li>
          <li>Нахиляйте вгору та вниз</li>
          <li>Насолоджуйтеся</li>
        </ol>
        <a href="#form"><button type='button' className='btn green_btn btn-lg mt-3 col-12'>СПРОБУВАТИ!</button></a>
      </div>
      <div className='containered gray'>
        <ImageGallery
          showFullscreenButton={false} 
          autoPlay={true} 
          showBullets={true}
          showPlayButton={false} 
          additionalClass=""
          items={images} />
        <h2 className=' mt-2 mb-1'><small><s>173 грн</s></small> <b><big className='highlight'>129 грн.</big></b></h2>
        <p className=' m-0'>**Передбачення з Сільпо всередині!</p>
      </div>
      <div className='containered' id='form'>
        <hr className='mt-2 mb-4'/>
        <h2 className='text-center fw-bold mb-0'>Встигніть замовити!</h2>
        <p className='text-center m-0'>Кількість обмежена</p>
        <input onChange={(e) => {let val = e.target.value.trim(); setName(val === '' ? false : val)}} className='form-control form-control-lg mb-2 mt-4' name='name' placeholder="Ваше ім'я"/>
        <InputMask mask="+38 099 999 99 99" onChange={changePhone}>
          <input className='form-control form-control-lg' name='name' placeholder="Номер телефону"/>
        </InputMask>
        <button onClick={sendApply} type='button' className='btn btn-success btn mt-3 mx-auto'><b><small>ЗАЛИШИТИ ЗАЯВКУ</small></b></button>
        <p className='text-center mt-2 mb-2'>Або</p>
        <button onClick={() => window.open('https://t.me/CoolCuteStuff_bot?start=pop-cat-keychain', '_blank').focus()} type='button' className='btn btn-primary btn mx-auto mb-2'><small><b>В ОДИН КЛІК</b></small>&nbsp;&nbsp;<i className="fab fa-telegram-plane"></i></button>
        <hr className='mt-4 mb-1'/>
      </div>
      <div className='containered dark'>
        <p className='mb-0 mt-0 text-secondary'>*По факту, Ви купляєте двох котів, а не одного )</p>
        <p className='m-0 p_lg text-light'>Залишок, станом на 13.01:</p>
        <img src={limited} className='limit_img mt-3 mx-auto' alt="Кількість обмежена"/>
        <hr/>
        <p className='text-center m-0 text-light'>Sending you best wishes for an amazing journey ahead!</p>
      </div>
      {/* <h6 className='text-center wheat m-0'>Дякую за увагу! 
        <a href='#form' className='link'>Форма вище )</a>
      </h6> */}
    </div>
  );
}

export default App;
